@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.8) rotate(5deg);
    }
    50% {
      opacity: 1;
      transform: scale(1.05) rotate(0deg);
    }
    100% {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }
@keyframes veil {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.intro__bg{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 750px;
  &--img{
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transform: scale(0.8) rotate(5deg);
    animation: fadeIn 1s forwards ease-out;
    animation-fill-mode: both;
  }
}
.intro__cadre{
  top: -475px;
  position: relative;
  opacity: 0; 
  height: 200px;
  display: flex;
  border-radius: 25px;
  justify-content: center;
  animation: appear 2s forwards;
  animation-delay: #{13 * 0.1 + 2}s;
  &--video{
    width: 100%;
  }
}
@media (min-width: 768px){
  .intro__bg{
    height: 500px;
  }
  .intro__cadre{
    top: -350px;
  }
}
@media (min-width: 1025px){
  .intro__bg{
    height: 800px;
  }
  .intro__cadre{
    top: -550px;
    height: 300px;
    margin-left: 35%;
  }
}
@media (min-width: 1441px){
  .intro__bg{
    height: 950px;
  }
}



