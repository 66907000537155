$c-bg: #141015;
$c-blue: #00FFFF;
$c-purple: #8F00FF;
$c-pink: #FF00D4;
$c-text: #FAFAFA;

$size-text: 16px;

$font: "Aldrich", sans-serif;
$font-title: "Oxanium", sans-serif;
