.switch{
    margin-top: 6%;
    display: flex;
    justify-content: center;
}
.checkbox {
    display: none;
  }
.slider {
    width: 60px;
    height: 30px;
    background-color: $c-blue;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 4px solid transparent;
    transition: .3s;
    box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
    cursor: pointer;
}
.slider::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #FAFAFA;
    transform: translateX(-30px);
    border-radius: 20px;
    transition: .3s;
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
} 
.checkbox:checked ~ .slider::before {
    transform: translateX(30px);
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}
.checkbox:checked ~ .slider {
    background-color: $c-pink;
}
.checkbox:active ~ .slider::before {
    transform: translate(0);
}

@media (min-width: 768px){
    .switch{
        display: block;
        margin-left: 25%;
        margin-right: 75%;
    }
}
@media (min-width: 1025px){
    .switch{
        margin-left: 30%;
    }
}
@media (min-width: 1441px){
    .switch{
        margin-left: 30%;
        margin-top: 2%;
    }
}