.presentation{
  position: relative;
  background-image: url(../assets/images/dot-bg.svg);
  background-position: 0 0;
  background-size: 50px;
  background-attachment: fixed;
  top: -220px;
  &__title{
    margin-left: 25px;
  }
}
.transitionUP__section{
  height: 25px;
  width: 100%;
  &--img{
    background-image: url(../assets/images/transisvg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
}
.transitionDOWN__section{
  height: 25px;
  width: 100%;
  transform: rotate(180deg);
  margin-bottom: 5%;
  z-index: 3;
  position: relative;
  &--img{
    background-image: url(../assets/images/transisvg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
}
.cards{
  display: flex;
  width: 100%;
  height: 300px;
  position: relative;
  z-index: 10;
  &__el{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &--wrapper{
      transition: all 0.5s;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      z-index: -1;
      .cover-image{
        width: 33.33vw;
        height: 300px;
        object-fit: cover;
      }
    }
    &--title{
      width: 100%;
    }
    &--character{
      display: none;
    }
  }
}
.presentation__section{
  margin-top: 20%;
  &--title{
    text-align: right;
    position: relative;
    padding-bottom: 10px;
    margin-top: 0;
    margin-right: 5%;
  }
  &--title::after{
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 60%;
    height: 3px;
    background: linear-gradient(to right, $c-blue, $c-purple, $c-pink);
  }
  &--img{
    margin-top: 7%;
    margin-bottom: 7%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    filter: drop-shadow(8px 5px 8px rgba(0, 255, 255, 0.100));
  }
  &--p{
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (min-width: 768px){
  .presentation{
    top: -225px;
    &__title{
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  .transitionUP__section{
    height: 35px;
  }
  .transitionDOWN__section{
    height: 35px;
  }
  .cards{
    height: 350px;
    &__el{
      &--wrapper{
        margin-left: 5%;
        margin-right: 5%;
        z-index: -1;
        .cover-image{
          width: 200px;
          height: 350px;
        }
      }
      &--title{
        width: 80%;
      }
      &--character{
        display: none;
      }
    }
  }
  .presentation__section{
    margin-top: 10%;
    display: grid;
    grid-template-columns: 5% 1fr 5% 1fr 5%;
    margin-bottom: 10%;
    &--img{
      width: 100%;
      height: 100%;
      margin: 0;
      grid-column: 2/3;
      grid-row: 2 / 4;
    }
    &--title{
      grid-column: 1/5;
      grid-row: 1;
    }
    &--p{
      margin: 0;
    }
    &--p:nth-child(3){
      grid-column: 4/5;
      grid-row: 2;
    }
    &--p:nth-child(4){
      margin-top: 5%;
      grid-column: 4/5;
      grid-row: 3 / 4;
    }
    &--p:nth-child(5){
      grid-column: 2/5;
      grid-row: 5;
      margin-top: 5%;
    }
  }
}

@media (min-width: 1025px){
  .presentation{
    top: -335px;
  }
  .transitionUP__section{
    height: 55px;
  }
  .transitionDOWN__section{
    height: 55px;
  }
  .cards {
    margin-top: 7%;
    display: flex;
    justify-content: space-between;
    &__el {
      width: var(--card-width);
      height: var(--card-height);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 0 36px;
      perspective: 2500px;
      &:hover {
        .cards__el--wrapper {
          transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
          &::before,
          &::after {
            opacity: 1;
          }
          &::after {
            height: 120px;
          }
        }
        .cards__el--title {
          transform: translate3d(0%, -50px, 100px);
        }
        .cards__el--character {
          opacity: 1;
          transform: translate3d(0%, -5%, 100px);
        }
      }
      &--wrapper {
        transition: all 0.5s;
        position: absolute;
        width: revert-layer;
        z-index: -1;
        &::before,
        &::after {
          content: "";
          opacity: 0;
          width: 100%;
          height: 80px;
          transition: all 0.5s;
          position: absolute;
          left: 0;
        }
        &::before {
          top: 0;
          height: 100%;
          background-image: linear-gradient(
            to top,
            transparent 46%,
            rgba(12, 13, 19, 0.5) 68%,
            rgba(12, 13, 19) 97%
          );
        }
        &::after {
          bottom: 0;
          opacity: 1;
          background-image: linear-gradient(
            to bottom,
            transparent 46%,
            rgba(12, 13, 19, 0.5) 68%,
            rgba(12, 13, 19) 97%
          );
        }
      }
      .cover-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &--title {
        width: 100%;
        transition: transform 0.5s;
      }
      &--character {
        width: 100%;
        opacity: 0;
        display: block;
        transition: all 0.5s;
        position: absolute;
        z-index: -1;
      }
    }
  }
  .presentation__section{
    &--img{
      grid-row: 2 / 4;
    }
    &--p{
      margin: 0;
      & a:hover{
        @include gradient-textH;
        color: $c-text;
      }
    }
    &--p:nth-child(3){
      margin-bottom: 5%;
    }
    &--p:nth-child(4){
      margin-top: 5%;
      margin-bottom: 5%;
    }
    &--p:nth-child(5){
      margin-top: 5%;
      grid-column: 2/5;
      grid-row: 4;
    }
  }
}
@media (min-width: 1441px){
  .presentation{
    top: -350px;
  }
  .cards{
    margin-top: 12%;
  }
  .presentation__section{
    grid-template-columns: 10% 1fr 5% 1fr 5%;
    &--img{
      grid-row: 2 / 5;
    }
    &--p{
      margin: 0;
    }
    &--p:nth-child(5){
      grid-column: 4/5;
      grid-row: 4;
    }
  }
}
@media (min-width: 1700px){
  .presentation__section{
  grid-template-columns: 20% 1fr 5% 1fr 5%;
  }
  .cards{
    margin-top: 15%;
  }
}

