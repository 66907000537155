.intro__bg{
    &--img:nth-child(1) {
      background-image: url('../assets/images/bg-intro-mb1/1.png');
      animation-delay: 0.1s;
    }
    &--img:nth-child(2) {
      background-image: url('../assets/images/bg-intro-mb1/2.png');
      animation-delay: 0.2s;
    }
    &--img:nth-child(3) {
      background-image: url('../assets/images/bg-intro-mb1/3.png');
      animation-delay: 0.3s;
    }
    &--img:nth-child(4) {
      background-image: url('../assets/images/bg-intro-mb1/4.png');
      animation-delay: 0.4s;
    }
    &--img:nth-child(5) {
      background-image: url('../assets/images/bg-intro-mb1/5.png');
      animation-delay: 0.5s;
    }
    &--img:nth-child(6) {
      background-image: url('../assets/images/bg-intro-mb1/6.png');
      animation-delay: 0.6s;
    }
    &--img:nth-child(7) {
      background-image: url('../assets/images/bg-intro-mb1/7.png');
      animation-delay: 0.7s;
    }
    &--img:nth-child(8) {
      background-image: url('../assets/images/bg-intro-mb1/8.png');
      animation-delay: 0.8s;
    }
    &--img:nth-child(9) {
      background-image: url('../assets/images/bg-intro-mb1/9.png');
      animation-delay: 0.9s;
    }
    &--img:nth-child(10) {
      background-image: url('../assets/images/bg-intro-mb1/10.png');
      animation-delay: 1s;
    }
    &--img:nth-child(11) {
      background-image: url('../assets/images/bg-intro-mb1/11.png');
      animation-delay: 1.1s;
    }
    &--img:nth-child(12) {
      background-image: url('../assets/images/bg-intro-mb1/12.png');
      animation-delay: 1.2s;
    }
    &--img:nth-child(13) {
      background-image: url('../assets/images/bg-intro-mb1/13.png');
      animation-delay: 1.3s;
    }
    &--img:nth-child(14){
      background-image: url('../assets/images/bg-intro-mb1/69.svg');
      opacity: 1;
      transform: translateY(-100%);
      animation: veil 2s forwards ease-in-out;
      animation-delay: #{13 * 0.1 + 1}s;
    }
}
@media (min-width: 768px){
    .intro__bg{
      &--img:nth-child(1) {
        background-image: url('../assets/images/bg-intro-tab1/1.png');
        animation-delay: 0.1s;
      }
      &--img:nth-child(2) {
        background-image: url('../assets/images/bg-intro-tab1/2.png');
        animation-delay: 0.2s;
      }
      &--img:nth-child(3) {
        background-image: url('../assets/images/bg-intro-tab1/3.png');
        animation-delay: 0.3s;
      }
      &--img:nth-child(4) {
        background-image: url('../assets/images/bg-intro-tab1/4.png');
        animation-delay: 0.4s;
      }
      &--img:nth-child(5) {
        background-image: url('../assets/images/bg-intro-tab1/5.png');
        animation-delay: 0.5s;
      }
      &--img:nth-child(6) {
        background-image: url('../assets/images/bg-intro-tab1/6.png');
        animation-delay: 0.6s;
      }
      &--img:nth-child(7) {
        background-image: url('../assets/images/bg-intro-tab1/7.png');
        animation-delay: 0.7s;
      }
      &--img:nth-child(8) {
        background-image: url('../assets/images/bg-intro-tab1/8.png');
        animation-delay: 0.8s;
      }
      &--img:nth-child(9) {
        background-image: url('../assets/images/bg-intro-tab1/9.png');
        animation-delay: 0.9s;
      }
      &--img:nth-child(10) {
        background-image: url('../assets/images/bg-intro-tab1/10.png');
        animation-delay: 1s;
      }
      &--img:nth-child(11) {
        background-image: url('../assets/images/bg-intro-tab1/11.png');
        animation-delay: 1.1s;
      }
      &--img:nth-child(12) {
        background-image: url('../assets/images/bg-intro-tab1/12.png');
        animation-delay: 1.2s;
      }
      &--img:nth-child(13) {
        background-image: url('../assets/images/bg-intro-tab1/13.png');
        animation-delay: 1.3s;
      }
    }
  }