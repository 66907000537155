.menu{
    &__home{
        &--txt{
            position: absolute;
            visibility: hidden;
            pointer-events: none; 
        }
        &--img{
            position: fixed;
            top: 10px;
            left: 10px;
            z-index: 30;
            width: 45px;
            height: 45px;
            padding: 7.5px;
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
    &__burger{
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 30;
        width: 60px;
        height: 60px;
        transition: 0.4s;
        background-color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        &--lines::before,
        &--lines::after,
        &--lines {
            position: absolute;
            width: 50px;
            height: 3px;
            background: linear-gradient(to right, $c-blue, $c-purple, $c-pink);
            transition: 0.4s;
        }
        &--lines {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        &--lines::before {
            content: '';
            top: -12px;
          }
        &--lines::after {
            content: '';
            top: 12px;
          }
    }
    &__burger:hover .menu__burger--lines{
        filter: brightness(0.7);
    }
    
    &__burger:hover{
        background-color: rgba(255, 255, 255, 0.7);
    }
    &__list{
        padding-top: 150px;
        margin-top: 0;
        padding-left: 0;
        list-style: none;
        position: fixed;
        width: 40%;
        top: 0;
        z-index: 20;
        display: flex;
        flex-flow: column;
        height: 100%;
        background-color: rgba(20, 16, 21, 0.8);
        background-image: url(../assets/images/dot-bg.svg);
        background-position: 0 0;
        background-size: 50px;
        overflow-y: auto;
        right: -100%;
        transition: 0.8s;
        &--el{
            padding: 18px 35px;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 5px;
            font-weight: 400;
            border-bottom: 1px solid $c-blue;
            & a{
                text-decoration: none;
                color: $c-text;
            }
            &:first-child{
                border-top: 1px solid $c-blue; 
            }
        }
        &--el:hover{
            filter: brightness(0.9);
        }
    }
    &__overlay{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
}
.menu--open .menu__list{
    right: 0;
    transition: 0.6s;
}
.menu--open .menu__burger--lines {
    background: transparent;
}
.menu--open .menu__burger--lines::before {
    top: 0;
    transform: rotate(45deg);
}
.menu--open .menu__burger--lines::after{
    top: 0;
    transform: rotate(-45deg);
}
.menu--open .menu__overlay{
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 1025px){
    .menu{
        position: fixed;
        top: 20px;
        left: 0;
        padding: 0;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        height: 60px;
        z-index: 1000;
        background-color: rgba(250, 250, 250, 0.900);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__burger{
            display: none;
        }
        &__home{
            display: flex;
            left: 5%;
            align-items: center;
            position: absolute;
            &--img{
                position: revert-layer;
                top: 0;
                width: 45px;
                height: 45px;
                padding: 7.5px;
                padding-left: 18px;
                background: none;
            }
            &--txt{
                visibility: visible;
                pointer-events: revert-layer;
                cursor: pointer;
                margin-left: 40px;
                color: $c-bg;
                text-transform: uppercase;
                letter-spacing: 5px;
                padding: 17px 35px 16px 35px;
                font-weight: bold;
                font-size: 18px;
            }
        }
        &__home:hover{
            .menu__home--txt{
                background-color: $c-bg;
                color: $c-text;
            }
            .menu__home--img{
                background-color: $c-bg;
            }
        }
        &__list{
            position: relative;
            left: 40%;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            width: 100%;
            z-index: 5;
            overflow: hidden;
            background: none;
            height: revert-layer;
            &--el{
                border-bottom: none;
            }
            &--el:first-child{
                border-top: none;
            }
            &--el a{
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;
                color: $c-bg;
            }
            &--el:hover{
                background-color: $c-bg;
                & a{
                    color: $c-text;
                }
            }
            &--active{
                background-color: $c-bg;
                & a{
                    color: $c-text;
                }
            }
        }
    }
}
@media (min-width: 1441px){
    .menu{
        &__home{
            left: 10%;
        }
        &__list{
            left: 55%;
        }
    }
}
@media (min-width: 1700px){
    .menu{
        &__list{
            left: 60%;
        }
    }
}