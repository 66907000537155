.subnav{
    margin-top: -35%;
    position: relative;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: rgba(250, 250, 250, 0.900);
    border-radius: 8px;
    bottom: 20px;
    z-index: 1000;
    & a{
        text-decoration: none;
        color: $c-bg;
        font-size: 14px;
        display: inline-block;
        padding: 18px;
    }
    & a:nth-child(1){
        margin-left: 5%;
    }
    &__list{
        position: absolute;
        list-style: none;
        right: 5%;
        display: flex;
        gap: 10px;
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 768px){
    .subnav{
        margin-top: -20%;
        & a{
            font-size: 18px;
        }
        &__list{
            gap: 40px;
        }
    }
}
@media (min-width: 1025px){
    .subnav{
        & a:hover{
            background-color: $c-bg;
            color: $c-text;
        }

        &__list{
            gap: 60px;
        }
    }
}
@media (min-width: 1441px){
    .subnav{
        margin-top: -10%;
    }
}
