.work{
  position: relative;
  top: -260px;
  width: 100%;
  display: flex;
  justify-content: center;
  &__bg{
    width: 100%;
    height: 860px;
    &--video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title{
    display: none;
  }
}
@media (min-width: 500px) {
  .work{
    top: -270px;
  }
}
@media (min-width: 700px) {
  .work{
    top: -280px;
  }
}
.project{
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__el{
    width: 100%;
    &--active{
      .project__modal{
        opacity: 1;
        pointer-events: all;
        transform: translate(-50%, -50%);
      }
      .project__overlay{
        opacity: .5;
        pointer-events: all;
      }
    }
  }
  &__link{
    text-decoration: none;
  }
  &__avatar{
    margin: 0;
    background-color: rgba(20, 16, 21, 0.700);
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
    margin-bottom: 5%;
    box-shadow: 5px 8px 20px rgba(20, 16, 21, 0.7);
    &--img{
      width: 80px;
      height: 130px;
    }
    &--name{
      text-align: center;
      color: $c-text;
    }
  }
  &__modal{
    position: absolute;
    width: 200%;
    height: 560px;
    top: 53%;
    left: 50%;
    background-image: url(../assets/images/modal-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate(-50%, -60%);
    z-index: 15;
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 30px;
    &--title{
      margin-top: 3%;
      margin-bottom: 5%;
    }
    &--subtitle{
      text-align: end;
      margin-top: 9%;
      margin-bottom: 9%;
      position: relative;
      padding-bottom: 5px;
    }
    &--subtitle::after{
      content: '';
      position: absolute;
      right: 0px;
      bottom: 0;
      width: 90%;
      height: 3px;
      background: linear-gradient(to right, $c-blue, $c-purple, $c-pink);
    }
    &--txt{
      margin-top: 9%;
      margin-bottom: 15%;
    }
    &--img{
      width: 90%;
    }
    &--video{
      width: 100%;
    }
    &--link{
      margin-top: 9%;
      margin-bottom: 9%;
      margin-left: 30%;
      align-items: center;
      background-image: linear-gradient(144deg,$c-pink, $c-purple 50%,$c-blue);
      border: 0;
      border-radius: 8px;
      box-shadow: rgba(20, 16, 21, 0.2) 0 15px 30px -5px;
      box-sizing: border-box;
      color: $c-text;
      display: flex;
      font-size: 20px;
      justify-content: center;
      line-height: 1em;
      max-width: 100%;
      min-width: 130px;
      width: 130px;
      padding: 3px;
      text-decoration: none;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      cursor: pointer;
    }
    &--link:active,
    &--link:hover{
      outline: 0;
    }
    &--link span{
      background-color: rgb(5, 6, 45);
      padding: 16px 16px;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      transition: 300ms;
    }
    &--link:hover span{
      background: none;
    }
    .project__list{
      list-style: square;
      margin-bottom: 15%;
      padding-left: 60px;
      &--el{
        font-size: 14px;
      }
      &--el a{
        text-decoration: none;
      }
    }
  }
  &__btn{
    position: relative;
    width: 100%;
    height: 10%;
  }
  &__close{
    position: absolute;
    top: 5px;
    right: -22px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background-color: inherit;
    padding: 0;
    border: none;
    cursor: pointer;
    width: 44px;
    height: 44px;
    background-image: url(../assets/images/close-btn.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__overlay{
    background-color: #000;
    opacity: .5;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: .3s;
  }
}
.image__right{
  margin-left: 10%;
}
@media (min-width: 768px){
  .work{
    top: -300px;
    &__bg{
      height: 700px;
      &--video{
        position: absolute;
        z-index: 1;
      }
      &--overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        pointer-events: none;
        background-image: url(../assets/images/ordi.png);
      }
    }
  }
  .project{
    z-index: 3;
    flex-direction: row;
    top: 200px;
    gap: 20px;
    &__modal{
      width: 100%;
      height: 400px;
      &--img{
        display: block;
        margin: 0 auto;
      }
      &--txt{
        margin-left: 15%;
      }
      &--link{
        margin-left: 40%;
      }
      .project__list{
        margin-left: 40%;
        &--el{
          font-size: 18px;
        }
      }
    }
    &__close{
      width: 55px;
      height: 55px;
    }
  }
  .paysage{
    width: 80%;
  }
  .portrait{
    width: 50%;
  }
}
@media (min-width: 1025px){
  .work{
    top: -430px;
    &__bg{
      height: 950px;
    }
  }
  .project{
    top: 300px;
    &__el:hover{
      .project__avatar{
        transform: scale(0.8);
      }
    }
    &__modal{
      width: 142%;
      height: 525px;
      &--txt{
        margin-bottom: 5%;
      }
      &--link{
        margin-left: 43%;
      }
      .project__list{
        margin-left: 40%;
        &--el{
          font-size: 22px;
        }
        &--el a:hover{
          @include gradient-textH;
          color: $c-text;
        }
      }
    }
    &__close{
      top: 23px;
      right: -10px;
      width: 66px;
      height: 66px;
    }
  }
  .paysage{
    width: 70%;
  }
  .portrait{
    width: 40%;
  }
}
@media (min-width: 1441px){
  .work{
    top: -470px;
    &__bg{
      height: 1050px;
    }
  }
  .project{
    top: 280px;
    &__avatar{
      &--img{
        width: 160px;
        height: 260px;
      }
    }
    &__modal{
      width: 100%;
      height: 620px;
      &--txt{
        margin-bottom: 5%;
      }
      &--link{
        margin-left: 43%;
      }
      .project__list{
        margin-left: 40%;
        &--el{
          font-size: 22px;
        }
      }
    }
    &__close{
      top: 23px;
      right: -10px;
      width: 66px;
      height: 66px;
    }
  }
  .paysage{
    width: 70%;
  }
  .portrait{
    width: 40%;
  }
}
@media (min-width: 1700px){
  .work{
    top: -490px;
    &__bg{
      height: 1180px;
    }
  }
  .project{
    top: 300px;
    &__avatar{
      &--img{
        width: 160px;
        height: 260px;
      }
    }
    &__modal{
      width: 140%;
      height: 760px;
      padding-left: 60px;
      padding-right: 90px;
      &--txt{
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 40%;
      }
      &--link{
        margin-left: 45%;
        transform: scale(1.5);
      }
      &--subtitle{
        margin-bottom: 3%;
      }
      &--subtitle::after{
        width: 70%;
      }
      .project__list{
        margin-left: 60%;
        margin-bottom: 3%;
        &--el{
          font-size: 22px;
        }
      }
    }
    &__close{
      top: 45px;
      right: -45px;
      width: 77px;
      height: 77px;
    }
  }
}