#content{
    display: none;
}
#loading-screen{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $c-bg;
    z-index: 1000;
    .spinner{
        background-image: linear-gradient($c-pink 35%, $c-blue);
        width: 100px;
        height: 100px;
        animation: spinning82341 1.7s linear infinite;
        text-align: center;
        border-radius: 50px;
        filter: blur(1px);
        box-shadow: 0px -5px 20px 0px $c-pink, 0px 5px 20px 0px $c-blue;
        &1{
            background-color: $c-bg;
            width: 100px;
            height: 100px;
            border-radius: 50px;
            filter: blur(10px);  
        }
    }
}
@keyframes spinning82341 {
    to {
      transform: rotate(360deg);
    }
  }