body {
  font-family: $font;
  font-size: $size-text;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  color: $c-text;
  background-color: $c-bg;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
.big-title{
  font-family: $font-title;
  font-weight: 800;
  font-size: 44px;
}
.title{
  font-family: $font-title;
  font-weight: 800;
  font-size: 36px;
}
.sub-title{
  font-family: $font-title;
  font-weight: 800;
  font-size: 24px;
}
.uppercase{
  text-transform: uppercase;
}
.bold{
  font-weight: bold;
}
@mixin gradient-text {
  background: linear-gradient(to top, $c-pink 0%, $c-blue 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin gradient-textH {
  background: linear-gradient(to bottom, $c-pink 0%, $c-blue 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text {
  @include gradient-text;
  color: $c-text;
}
@media (min-width: 560px){
  body {
    font-size: 18px;
  }
  .big-title{
    font-size: 51px;
  }
  .title{
    font-size: 41px;
  }
  .sub-title{
    font-size: 27px;
  }
}
@media (min-width: 768px){
  body {
    font-size: 20px;
  }
  .big-title{
    font-size: 58px;
  }
  .title{
    font-size: 45px;
  }
  .sub-title{
    font-size: 30px;
  }
}
@media (min-width: 1025px){
  body {
    font-size: 26px;
  }
  .big-title{
    font-size: 68px;
  }
  .title{
    font-size: 59px;
  }
  .sub-title{
    font-size: 39px;
  }
}
@media (min-width: 1441px) {
  .big-title{
    font-size: 88px;
  }
}