@import 'utils/variables';
@import 'components/type';
@import 'components/introBG';
@import 'components/intro';
@import 'components/nav';
@import 'components/presentation';
@import 'components/work';
@import 'components/loadscreen';
@import 'components/switch';
@import 'components/skills';
@import 'components/footer';

.container{
    max-width: 1920px;
    min-width: 350px;
}
